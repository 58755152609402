import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobService } from 'src/app/services/job.service';
import { ApplyJobComponent } from '../apply-job/apply-job.component';
import { AppConstants } from 'src/app/appconstants';
import { JobApplicationRemovalComponent } from '../dashboard/job-seeker-dashboard/view-applied-jobs/job-application-removal/job-application-removal.component';
import { JobUpdateService } from 'src/app/services/job-update.service';
import { DateUtilsService } from 'src/app/utils/date-utils.service';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/authguard/auth.service';

@Component({
  selector: 'app-job-details-modal',
  templateUrl: './job-details-modal.component.html',
  styleUrl: './job-details-modal.component.css'
})
export class JobDetailsModalComponent implements OnInit {
    currentJob: any;
  isSaved: boolean = false;
  applyJobDialogRef!: any;
  user: any;
  jobSaveId: any;
  loading: boolean = true; // Loading state
  isLoggedIn: any;
  isJobSeeker: boolean = false;

  isAppliedJob: boolean = false;
  constructor(
    private jobService: JobService,
    private dateUtilsService: DateUtilsService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { id: string, applicationInfo: any },
    private dialogRef: MatDialogRef<JobDetailsModalComponent>,
    private removeDialogRef: MatDialogRef<JobApplicationRemovalComponent>,
    private jobUpdateService: JobUpdateService,
    private profileService: ProfileService,
    private authservice: AuthService,
  ) {

  }

  ngOnInit(): void {
    const loginStatus = localStorage.getItem(AppConstants.LOGINSTATUS);
    this.isLoggedIn = loginStatus == AppConstants.TRUE ? true : false;
    if (this.isLoggedIn) {
      this.user =  JSON.parse(localStorage.getItem(AppConstants.USER) || "");
    }
    
    this.isAppliedJob = this.data?.applicationInfo?.isAppliedJob;
    this.getJobDetails(this.data.id);
    this.isJobSeeker = this.profileService.getUserClaims()?.role == 'Job Seeker';
  }

  getJobDetails(jobId: string) {
    this.jobService.getJobById(jobId).subscribe((result: any) => {
      this.currentJob = result.data;
      this.loading=false;
       if (this.isLoggedIn) {
         this.jobService.isJobSavedForJobSeeker(this.data.id, this.user.userId)
         .subscribe((jobSaveResult: any) => {
           this.isSaved = jobSaveResult.data.isJobSaved;
           this.jobSaveId = jobSaveResult.data.jobSaveId;
           this.loading = false;
         });
       }            
    });
  }

  getFormattedDate(date: any): string {
    return date ? this.dateUtilsService.convertToMMDDYYYY(date) : '';
  }

  applyNowModalOpen() {
    if (this.isLoggedIn){
      this.applyJobDialogRef = this.matDialog.open(ApplyJobComponent,
        {
          data: { id: this.data.id }
        }
      );
      this.dialogRef.close();
      this.applyJobDialogRef.afterClosed().subscribe((result: any) => {
        this.getJobDetails(this.data.id);
      });
    } else {
      this.authservice.login();
    }
    
  }

  saveClick() {
    if (!this.isSaved) {     

      var requestData = {
        jobId: this.data.id,
        userId: this.user.userId
      };
  
      this.jobService.saveJobForJobSeeker(requestData)
      .subscribe((result: any) => {
        if (result.success) {
          this.jobSaveId = result.data.jobSaveId;
          this.isSaved = !this.isSaved;
          this.jobUpdateService.notifyJobUpdated();
        }      
      });
    } else {
      var removeSavedJobArray = [];
      removeSavedJobArray.push(this.jobSaveId);
      this.jobService.removeSavedJobs(removeSavedJobArray)
      .subscribe((result: any) => {
        if (result.success) {
          this.isSaved = false;
          this.jobUpdateService.notifyJobUpdated();
        }
      });
    }
  }

  print() {
let printContent = document.getElementById('print-area')?.innerHTML;

    const tempDiv = document.createElement('div');
    if(printContent){
      tempDiv.innerHTML = printContent || '';
    }    
    const divsToRemove = tempDiv.querySelectorAll('.no-print-area');
    divsToRemove.forEach(div => {
        div.remove();
    });
    printContent = tempDiv.innerHTML;

    const newWindow = window.open('', '',);
    
    const styles = Array.from(document.styleSheets)
    .map(styleSheet => {
      try {
        return Array.from(styleSheet.cssRules)
          .map(rule => rule.cssText)
          .join('');
      } catch (e) {
        return '';
      }
    })
    .join('');

    newWindow?.document.write(`
      <html>
        <head>
        <title>LAWA Job Portal</title>
          <style>
          ${styles}
        </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    newWindow?.document.close();
    newWindow?.focus();
    newWindow?.print();
    newWindow?.addEventListener('afterprint', () => {
      newWindow.close();
    });
 }
  removeApplication() {

    this.removeDialogRef = this.matDialog.open(JobApplicationRemovalComponent, {
      data: {
        jobTitle: this.currentJob?.jobTitle
      },
    });

    this.removeDialogRef.afterClosed().subscribe((result: any) => {
      if (result?.isRemove)
        this.dialogRef.close({ data: result });
    });
  }
  getStatusTextClass(status: string): string {
    switch (status) {
      case 'Submitted': return 'color-blue';
      case 'Under Review': return 'color-orange';
      case 'Interview Scheduled': return 'color-purple';
      case 'Hired': return 'color-green';
      case 'Not Hired': return 'color-red';
      default:
        return 'status-default';
    }
  }
  getCompensationValue(currentJobData:any){
    let compensationValue='';
    if(currentJobData?.compensation?.typeName == AppConstants.SALARY)
      compensationValue = AppConstants.DOLLAR + currentJobData?.compensationMin + '- '+AppConstants.DOLLAR + currentJobData?.compensationMax + AppConstants.PERYEAR;
    else if(currentJobData?.compensation?.typeName == AppConstants.HOURLY)
      compensationValue = AppConstants.DOLLAR + currentJobData?.compensationMin + AppConstants.PERHOUR;
    return compensationValue;
  }
}
