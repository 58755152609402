import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private umbracoBaseUrl = environment.umbracoBaseUri;
  public constructor(private sanitizer: DomSanitizer) {}

  formatDateWithShortWeekday(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }

    const weekdayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    const monthOptions: Intl.DateTimeFormatOptions = { month: '2-digit' };
    const dayOptions: Intl.DateTimeFormatOptions = { day: '2-digit' };

    // Get the abbreviated weekday
    const weekday = date.toLocaleDateString('en-GB', weekdayOptions);
    const month = date.toLocaleDateString('en-GB', monthOptions);
    const day = date.toLocaleDateString('en-GB', dayOptions);
    const year = date.getUTCFullYear();

    return `${weekday}, ${month}/${day}/${year}`;
  }

  formatDateWithLongWeekday(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }

    const weekdayOptions: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    const dayOptions: Intl.DateTimeFormatOptions = { day: 'numeric' };

    // Get the full weekday name
    const weekday = date.toLocaleDateString('en-US', weekdayOptions);
    const month = date.toLocaleDateString('en-US', monthOptions);
    const day = date.toLocaleDateString('en-US', dayOptions);

    return `${weekday}, ${month} ${day}`;
  }

  formatTime(timeString: string): string {
    if (!timeString) return 'N/A';

    const parts = timeString.split('T')[1].split(':');
    let hours = parseInt(parts[0], 10);
    const minutes = parts[1];

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    return `${hours}:${minutes.padStart(2, '0')} ${period}`;
  }

  getEventTimeFrame(eventDate: string): string {
    const now = new Date();
    const event = new Date(eventDate);

    if (isNaN(event.getTime())) {
      return 'Invalid date';
    }
    const diffInDays = Math.ceil(
      (event.getTime() - now.getTime()) / (1000 * 3600 * 24)
    );
    if (diffInDays === 0) return 'Today';
    if (diffInDays === 1) return 'Tomorrow';
    return `In ${diffInDays} days`;
  }

  convertToHtml(data: any): SafeHtml {
    if (!data || !data.elements)
      return this.sanitizer.bypassSecurityTrustHtml('');
    const buildHtml = (element: any): string => {
      // Handle text nodes
      if (element.tag === '#text') {
        return element.text;
      }
      const attributes = Object.entries(element.attributes || {})
        .map(([key, value]) => ` ${key}="${value}"`)
        .join('');

      const innerHtml = element.elements
        ? element.elements.map(buildHtml).join('')
        : '';
      switch (element.tag) {
        case 'ul':
        case 'ol':
          return `<${element.tag}${attributes}>${innerHtml}</${element.tag}>`;
        case 'li':
          return `<li${attributes}>${innerHtml}</li>`;
        case 'img': return this.getImageUmbracoSource(element, attributes, innerHtml);
        default:
          return `<${element.tag}${attributes}>${innerHtml}</${element.tag}>`;
      }
    };

    //Process only the elements of the root
    let html = data.elements.map(buildHtml).join('');
    //const encodedDescription = this.encodeHtml(html);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getImageUmbracoSource(element: any, attributes: any, innerHtml: any): any {
    const srcRegex = /src="([^"]+)"/;     
    if (srcRegex.test(attributes)) {       // Replace the src value with the base URL prepended      
      attributes = attributes.replace(srcRegex, (match: any, srcValue: any) => {         
        return `src="${this.umbracoBaseUrl}/${srcValue}"`; 
      }); 
    }
     return `<${element.tag}${attributes}>${innerHtml}</${element.tag}>`
  }

  downloadFile(dataURI: string, fileName?: string): void {
    const [prefix, base64Data] = dataURI?.split(',') || [];
    const mimeType = prefix?.match(/data:([^;]+);base64/)?.[1] || '';
    const mimeToExtension: { [key: string]: string } = {
      'image/png': 'png',
      'image/jpeg': 'jpg',
      'application/pdf': 'pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'docx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'xlsx',
      'application/msword': 'doc',
      'text/plain': 'txt',
    };

    const fileExtension = mimeToExtension[mimeType] || 'bin';
    var newFlnmae = `document.${fileExtension}`;
    if (base64Data) {
      const linkSource = `data:${mimeType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName ? fileName : newFlnmae;
      downloadLink.click();
    } else {
      console.error('No file available for download');
    }
  }

  private encodeHtml(str: string): string {
    return str
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/@/g, '&#64;');
  }
}
