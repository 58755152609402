<div *ngIf="loading" class="spinner-overlay">
  <div class="spinner"></div>
</div>
<div
  *ngIf="!loading"
  id="print-area"
  class="dialog morejobModal right-job-desc-box"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header pb-1 d-flex justify-content-between px-4 pt-3">
        <div class="right-jobdesc-company-name d-flex align-items-center mb-3">
          <span
            ><img
              *ngIf="currentJob?.employer?.companyLogo"
              [src]="currentJob?.employer?.companyLogo"
              class="company-logo"
              alt="Logo of Company"
          /></span>
          <h2 class="mb-0" id="exampleModalLabel">
            {{ currentJob?.employer?.name }}
          </h2>
        </div>
        <div class="icons-right-top header-icons-right d-flex no-print-area">
          <img
            src="assets/icons/icon-tag.svg"
            (click)="saveClick()"
            *ngIf="!isSaved && isJobSeeker"
          />
          <img
            src="assets/icons/icon-tag-saved.svg"
            (click)="saveClick()"
            *ngIf="isSaved && isJobSeeker"
          />
          <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu">
            <button mat-menu-item (click)="print()">Print</button>
          </mat-menu>
        </div>
        <mat-icon class="close-icon no-print-area" mat-dialog-close
          >close</mat-icon
        >
      </div>
      <mat-dialog-content>
        <div class="modal-body">
          <div class="p-3 m-0">
            <div class="right-job-desc-top border-0 mb-0 pb-0">
              <div class="right-jobdesc-company-details">
                <span class="job-tag-count"
                  ><strong>#</strong> {{ currentJob?.jobId }}</span
                >
                <h3 class="mb-1">{{ currentJob?.jobTitle }}</h3>
                <ul class="d-flex mb-2 flex-wrap">
                  <li class="d-flex align-items-center">
                    <img
                      _ngcontent-ng-c966856989=""
                      src="assets/icons/icon-building.svg"
                      alt=""
                    />
                    <span>{{ currentJob?.employer?.name }}</span>
                  </li>

                  <li class="d-flex align-items-center">
                    <img src="assets/icons/icon-calendar.svg" alt="" />
                    <span>{{ getFormattedDate(currentJob?.postDate) }}</span>
                  </li>
                  <li
                    *ngIf="currentJob?.expiryDate"
                    class="d-flex align-items-center"
                  >
                    <img
                      src="assets/icons/icon-time-progress.svg"
                      alt="Time Progress Indicator for Expiration Date"
                      Title="Expiration Date"
                    />
                    <span>{{ getFormattedDate(currentJob?.expiryDate) }}</span>
                  </li>
                </ul>
                <div
                  class="job-tags-wrp d-flex justify-content-between flex-wrap"
                >
                  <div class="job-tags-left d-flex flex-wrap">
                    <span class="job-chip chip1 mb-2">{{
                      currentJob?.employmentType?.name
                    }}</span>
                    <span
                      *ngFor="let industry of currentJob?.industries | keyvalue"
                      class="job-chip chip2 color-category1 mb-2"
                    >
                      {{ industry?.value }}
                    </span>
                    <span class="job-chip chip3 mb-2"
                      ><img
                        src="../../../assets/icons/icon-money.svg"
                        alt=""
                      />{{ getCompensationValue(currentJob) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <br />
            <button
              *ngIf="!isAppliedJob && (isJobSeeker || !isLoggedIn)"
              class="btn btn-apply-now no-print-area"
              (click)="applyNowModalOpen()"
            >
              Apply Now<img
                src="assets/icons/arrow-right.svg"
                alt="Navigate to Next"
              />
            </button>
            <div class="applied-job-details" *ngIf="isAppliedJob">
              <div
                class="d-flex justify-content-between flex-wrap align-items-center"
              >
                <div class="applied-job-left-det">
                  <label class="mb-2">Date Applied</label>
                  <span>{{
                    data?.applicationInfo?.dateApplied
                      | date : "dd-MM-yyyy HH:mm a"
                  }}</span>
                </div>
                <div class="applied-job-left-mid">
                  <label class="mb-2">Current Status</label>
                  <span
                    class="status-col"
                    [ngClass]="
                      getStatusTextClass(data?.applicationInfo?.status)
                    "
                    >{{ data?.applicationInfo?.status }}</span
                  >
                </div>
                <div class="applied-job-left-right">
                  <button class="btn btn-remove" (click)="removeApplication()">
                    Remove this Application
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="desc-content1 mb-4">
            <b>
              <h4>Description</h4>
            </b>
            <div [innerHTML]="currentJob?.jobDescription"></div>
            <hr />
            <div *ngIf="currentJob?.benefits">Benefits</div>
            <div
              *ngIf="currentJob?.benefits"
              class="job-tags-left d-flex flex-wrap"
            >
              <span
                *ngFor="let benefit of currentJob?.benefits | keyvalue"
                class="job-chip chip2 color-category1 mb-2"
              >
                {{ benefit?.value }}
              </span>
            </div>
            <div *ngIf="currentJob?.terminals">Terminal</div>
            <div
              *ngIf="currentJob?.terminals"
              class="job-tags-left d-flex flex-wrap"
            >
              <span
                *ngFor="let terminal of currentJob?.terminals | keyvalue"
                class="job-chip chip2 color-category1 mb-2"
              >
                {{ terminal?.value }} </span
              ><br />
            </div>
            <div *ngIf="currentJob.zipCode">Zip</div>
            <div
              *ngIf="currentJob.zipCode"
              class="job-tags-left d-flex flex-wrap"
            >
              <span class="job-chip chip3 mb-2"
                ><b>{{ currentJob?.zipCode }}</b></span
              ><br />
            </div>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </div>
</div>
